import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { GatewayCoreModule } from 'app/core/core.module';
import { GatewaySharedModule } from 'app/shared/shared.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { DpDatePickerModule } from 'ng2-date-picker';
import { AutofocusFixModule } from 'ngx-autofocus-fix';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrModule } from 'ngx-toastr';
import { GatewayAppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DialogAddSequenceComponent } from './dialog/dialog-add-sequence/dialog-add-sequence.component';
import { DialogBusStopDetailComponent } from './dialog/dialog-bus-stop-detail/dialog-bus-stop-detail.component';
import { DialogChangeDateLineComponent } from './dialog/dialog-change-date-line/dialog-change-date-line.component';
import { DialogChangeLabelComponent } from './dialog/dialog-change-label/dialog-change-label.component';
import { DialogChangePasswordComponent } from './dialog/dialog-change-password/dialog-change-password.component';
import { DialogChangeTemplateForDisplayComponent } from './dialog/dialog-change-template-for-display/dialog-change-template-for-display.component';
import { DialogChangeTemplateForExternalContentComponent } from './dialog/dialog-change-template-for-external-content/dialog-change-template-for-external-content.component';
import { DialogChangeTemplateComponent } from './dialog/dialog-change-template/dialog-change-template.component';
import { DialogChannelComponent } from './dialog/dialog-channel/dialog-channel.component';
import { DialogConfirmComponent } from './dialog/dialog-confirm/dialog-confirm.component';
import { DialogCustomSortComponent } from './dialog/dialog-custom-sort/dialog-custom-sort.component';
import { DialogDeliveryJobComponent } from './dialog/dialog-delivery-job/dialog-delivery-job.component';
import { DialogDeliveryManagerSettingComponent } from './dialog/dialog-delivery-manager-setting/dialog-delivery-manager-setting.component';
import { DialogDeliverySimpleComponent } from './dialog/dialog-delivery-simple/dialog-delivery-simple.component';
import { DialogDeliveryTimetableComponent } from './dialog/dialog-delivery-timetable/dialog-delivery-timetable.component';
import { DialogDeliveryUploadComponent } from './dialog/dialog-delivery-upload/dialog-delivery-upload.component';
import { DialogDeviceManagerComponent } from './dialog/dialog-device-manager/dialog-device-manager.component';
import { DialogDisplayDeviceComponent } from './dialog/dialog-display-device/dialog-display-device.component';
import { DialogEditGroupComponent } from './dialog/dialog-edit-group/dialog-edit-group.component';
import { DialogEditHeaderComponent } from './dialog/dialog-edit-header/dialog-edit-header.component';
import { DialogEmergencyModeComponent } from './dialog/dialog-emergency-mode/dialog-emergency-mode.component';
import { DialogExportCalendarComponent } from './dialog/dialog-export-calendar/dialog-export-calendar.component';
import { DialogExtractSettingComponent } from './dialog/dialog-extract-setting/dialog-extract-setting.component';
import { DialogListCustomTagComponent } from './dialog/dialog-list-custom-tag/dialog-list-custom-tag.component';
import { DialogMessageComponent } from './dialog/dialog-message/dialog-message.component';
import { DialogOperationInformationSettingComponent } from './dialog/dialog-operation-information-setting/dialog-operation-information-setting.component';
import { DialogPlaylistRecurrenceComponent } from './dialog/dialog-playlist-recurrence/dialog-playlist-recurrence.component';
import { DialogPlaylistComponent } from './dialog/dialog-playlist/dialog-playlist.component';
import { DialogProjectManagerComponent } from './dialog/dialog-project-manager/dialog-project-manager.component';
import { DialogPublishDataBusInformationComponent } from './dialog/dialog-publish-data-bus-information/dialog-publish-data.component-bus-information';
import { DialogPublishDataSignageChannelComponent } from './dialog/dialog-publish-data-signage-channel/dialog-publish-data-signage-channel.component';
import { DialogPublishDataSignageDisplayComponent } from './dialog/dialog-publish-data-signage-display/dialog-publish-data-signage-display.component';
import { DialogPublishDataStationComponent } from './dialog/dialog-publish-data-station/dialog-publish-data-station.component';
import { DialogReferenceSettingComponent } from './dialog/dialog-reference-setting/dialog-reference-setting.component';
import { DialogRouteLabelManagerComponent } from './dialog/dialog-route-label-manager/dialog-route-label-manager.component';
import { DialogSelectGroupComponent } from './dialog/dialog-select-group/dialog-select-group.component';
import { DialogSettingDeviceMonitorAutoRefreshComponent } from './dialog/dialog-setting-device-monitor-auto-refresh/dialog-setting-device-monitor-auto-refresh.component';
import { DialogSettingExternalContentComponent } from './dialog/dialog-setting-external-content/dialog-setting-external-content.component';
import { DialogSettingProgramComponent } from './dialog/dialog-setting-program/dialog-setting-program.component';
import { DialogSettingSignageDisplayComponent } from './dialog/dialog-setting-signage-display/dialog-setting-signage-display.component';
import { DialogSettingComponent } from './dialog/dialog-setting/dialog-setting.component';
import { DialogSimpleSignageMessageComponent } from './dialog/dialog-simple-signage-message/dialog-simple-signage-message.component';
import { DialogSimpleSyncSettingComponent } from './dialog/dialog-simple-sync-setting/dialog-simple-sync-setting.component';
import { DialogTemplateDetailComponent } from './dialog/dialog-template-detail/dialog-template-detail.component';
import { DialogTemplateGroupDetailComponent } from './dialog/dialog-template-group-detail/dialog-template-group-detail.component';
import { DialogTextHighlightingSettingComponent } from './dialog/dialog-text-highlighting-setting/dialog-text-highlighting-setting.component';
import { DialogTimetableUpdateTimingComponent } from './dialog/dialog-timetable-update-timing/dialog-timetable-update-timming.component';
import { DialogUpperLimit } from './dialog/dialog-upper-limit/dialog-upper-limit.component';
import { DialogUserManagerComponent } from './dialog/dialog-user-manager/dialog-user-manager.component';
import { AutoFocusInvalidInputDirective } from './directive/auto-focus-invalid-input.directive';
import { BlockCopyPasteDirective } from './directive/block-copy-paste.directive';
import { BlockDropInInputTextDirective } from './directive/block-drop-in-input-text.directive';
import { CheckPrivilegesDirective } from './directive/check-privileges.directive';
import { CustomFocusoutDirective } from './directive/custom-focusout.directive';
import { DialogDragDropDirective } from './directive/dialog-drag-drop.directive';
import { DisableAfterClickDirective } from './directive/disable-after-click.directive';
import { MediaDragDirective } from './directive/media-drag.directive';
import { GatewayHomeModule } from './home/home.module';
import { ErrorComponent } from './layouts/error/error.component';
import { ExecutingComponent } from './layouts/executing/executing.component';
import { LayoutComponent } from './layouts/layout/layout.component';
import { Layout1Component } from './layouts/layout1/layout1.component';
import { Layout2Component } from './layouts/layout2/layout2.component';
import { Layout3Component } from './layouts/layout3/layout3.component';
import { Layout4Component } from './layouts/layout4/layout4.component';
import { Layout5Component } from './layouts/layout5/layout5.component';
import { Layout6Component } from './layouts/layout6/layout6.component';
// jhipster-needle-angular-add-module-import JHipster will add new module here
import { APP_INITIALIZER, Injector } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationInitializerFactory } from './blocks/config/translation.config';
import { DialogAreaItemSettingComponent } from './dialog-area-item-setting/dialog-area-item-setting.component';
import { DialogAreaSettingComponent } from './dialog-area-setting/dialog-area-setting.component';
import { DialogAddReservationComponent } from './dialog/dialog-add-reservation/dialog-add-reservation.component';
import { DialogApiDetailsComponent } from './dialog/dialog-api-details/dialog-api-details.component';
import { DialogAutoUpdatePlaylistComponent } from './dialog/dialog-auto-update-playlist/dialog-auto-update-playlist.component';
import { DialogCallApiCycleComponent } from './dialog/dialog-call-api-cycle/dialog-call-api-cycle.component';
import { DialogChangeTemplateForDestinationComponent } from './dialog/dialog-change-template-for-destination/dialog-change-template-for-destination.component';
import { DialogChooseCityComponent } from './dialog/dialog-choose-city/dialog-choose-city.component';
import { DialogConfirmDeleteAppComponent } from './dialog/dialog-confirm-delete-app/dialog-confirm-delete-app.component';
import { DialogConfirmReservationComponent } from './dialog/dialog-confirm-reservation/dialog-confirm-reservation.component';
import { DialogConfirmSaleTicketComponent } from './dialog/dialog-confirm-sale-ticket/dialog-confirm-sale-ticket.component';
import { DialogConfirmWebRefundTicketComponent } from './dialog/dialog-confirm-web-refund-ticket/dialog-confirm-web-refund-ticket.component';
import { DialogConfirmWithMultipleMessagesComponent } from './dialog/dialog-confirm-with-multiple-messages/dialog-confirm-with-multiple-messages.component';
import { DialogCreateDailyScheduleComponent } from './dialog/dialog-create-daily-schdule/dialog-create-daily-schedule.component';
import { DialogCreatePlaylistTabTranslationSpeechSynthesisComponent } from './dialog/dialog-create-playlist-tab-translation-speech-synthesis/dialog-create-playlist-tab-translation-speech-synthesis.component';
import { DialogDeliveryAnnouncementComponent } from './dialog/dialog-delivery-announcement/dialog-delivery-announcement.component';
import { DialogDeliveryApplicationTicketEditorComponent } from './dialog/dialog-delivery-application-ticket-editor/dialog-delivery-application-ticket-editor.component';
import { DialogDeliveryDestinationComponent } from './dialog/dialog-delivery-destination/dialog-delivery-destination.component';
import { DialogDownloadDataComponent } from './dialog/dialog-download-data/dialog-download-data.component';
import { DialogDuplicateApplicationDesignComponent } from './dialog/dialog-duplicate-application-design/dialog-duplicate-application-design.component';
import { DialogDuplicateSettingComponent } from './dialog/dialog-duplicate-setting/dialog-duplicate-setting.component';
import { DialogEditReservationComponent } from './dialog/dialog-edit-reservation/dialog-edit-reservation.component';
import { DialogEmergencyOperationComponent } from './dialog/dialog-emergency-operation/dialog-emergency-operation.component';
import { DialogExportReservationComponent } from './dialog/dialog-export-reservation/dialog-export-reservation.component';
import { DialogGlossaryComponent } from './dialog/dialog-glossary/dialog-glossary.component';
import { DialogHelpComponent } from './dialog/dialog-help/dialog-help.component';
import { DialogMessageOperationComponent } from './dialog/dialog-message-operation/dialog-message-operation.component';
import { DialogOperationInformationSettingScheduleComponent } from './dialog/dialog-operation-information-setting-schedule/dialog-operation-information-setting-schedule.component';
import { DialogPageSwitchingTimingComponent } from './dialog/dialog-page-switching-timing/dialog-page-switching-timing.component';
import { DialogPlayTimingSettingComponent } from './dialog/dialog-play-timing-setting/dialog-play-timing-setting.component';
import { DialogPlaybackTimeComponent } from './dialog/dialog-playback-time/dialog-playback-time.component';
import { DialogRouteSettingComponent } from './dialog/dialog-route-setting/dialog-route-setting.component';
import { DialogSettingAutoUpdateComponent } from './dialog/dialog-setting-auto-update/dialog-setting-auto-update.component';
import { DialogShowHiddenItemSettingSettingComponent } from './dialog/dialog-show-hidden-item-setting/dialog-show-hidden-item-setting.component';
import { DialogSpecificSequenceSettingComponent } from './dialog/dialog-specific-sequence-setting/dialog-specific-sequence-setting.component';
import { DialogTemplateSettingScheduleRegistrationComponent } from './dialog/dialog-template-setting-schedule-registration/dialog-template-setting-schedule-registration.component';
import { InfoTextHighlightingSettingComponent } from './dialog/dialog-text-highlighting-setting/info-text-highlighting/info-text-highlighting.component';
import { DialogTimetableSwitchTimingAreaComponent } from './dialog/dialog-timetable-switch-timing-area/dialog-timetable-switch-timing-area.component';
import { DialogTranslateTicketComponent } from './dialog/dialog-translate-ticket/dialog-translate-ticket.component';
import { DialogTrimingComponent } from './dialog/dialog-triming/dialog-triming.component';
import { DialogWebRefundTicketComponent } from './dialog/dialog-web-refund-ticket/dialog-web-refund-ticket.component';
import { DialogTemplateGroupLedComponent } from './dialog/led/dialog-template-group-led/dialog-template-group-led.component';
import { DialogTemplateLedComponent } from './dialog/led/dialog-template-led/dialog-template-led.component';
import { PriceTableComponent } from './dialog/price-table/price-table.component';
import { DialogTemplateSettingScheduleComponent } from './dialog/schedule/dialog-template-setting-schedule/dialog-template-setting-schedule.component';
import { MainComponent } from './layouts/main/main.component';
import { MenuAnnouncementManagerComponent } from './menu/menu-announcement-manager/menu-announcement-manager.component';
import { MenuBusInformationComponent } from './menu/menu-bus-information/menu-bus-information.component';
import { MenuDashboardComponent } from './menu/menu-dashboard/menu-dashboard.component';
import { MenuDeliveryComponent } from './menu/menu-delivery/menu-delivery.component';
import { MenuDestinationSignComponent } from './menu/menu-destination-sign/menu-destination-sign.component';
import { MenuDeviceComponent } from './menu/menu-device/menu-device.component';
import { MenuDigitalSignageComponent } from './menu/menu-digital-signage/menu-digital-signage.component';
import { MenuExternalContentManagerComponent } from './menu/menu-external-content-manager/menu-external-content-manager.component';
import { MenuIndexWordComponent } from './menu/menu-index-word/menu-index-word.component';
import { MenuLcdComponent } from './menu/menu-lcd/menu-lcd.component';
import { MenuLedLayoutEditorComponent } from './menu/menu-led-layout-editor/menu-led-layout-editor.component';
import { MenuMasterListComponent } from './menu/menu-master-list/menu-master-list.component';
import { MenuMediaComponent } from './menu/menu-media/menu-media.component';
import { MenuProjectComponent } from './menu/menu-project/menu-project.component';
import { MenuRouteListComponent } from './menu/menu-route-list/menu-route-list.component';
import { MenuScheduleMergeComponent } from './menu/menu-schedule-merge/menu-schedule-merge.component';
import { MenuScheduleOperationManagerComponent } from './menu/menu-schedule-operation-manager/menu-schedule-operation-manager.component';
import { MenuScheduleRegistrationComponent } from './menu/menu-schedule-registration/menu-schedule-registration.component';
import { MenuSignageDisplayEditorComponent } from './menu/menu-signage-display-editor/menu-signage-display-editor.component';
import { MenuSimpleSignageEditorComponent } from './menu/menu-simple-signage-editor/menu-simple-signage-editor.component';
import { MenuSpotComponent } from './menu/menu-spot/menu-spot.component';
import { MenuStationDisplayEditorComponent } from './menu/menu-station-display-editor/menu-station-display-editor.component';
import { MenuTicketEditorComponent } from './menu/menu-ticket-editor/menu-ticket-editor.component';
import { MenuTicketManagerComponent } from './menu/menu-ticket-manager/menu-ticket-manager.component';
import { MenuTimetableEditorComponent } from './menu/menu-timetable-editor/menu-timetable-editor.component';
import { MenuTimetableOperationManagerComponent } from './menu/menu-timetable-operation-manager/menu-timetable-operation-manager.component';
import { MenuUserComponent } from './menu/menu-user/menu-user.component';
import { AnnouncementManagerComponent } from './module/announcement-manager/announcement-manager.component';
import { BusInformationDisplayEditorComponent } from './module/bus-information-display-editor/bus-information-display-editor.component';
import { AnchorPickerComponent } from './module/custom-component/anchor-picker/anchor-picker.component';
import { ColorPickerLedComponent } from './module/custom-component/color-picker-led/color-picker-led.component';
import { ColorPalette330Component } from './module/custom-component/color-picker/color-palette-330/color-palette-330.component';
import { ColorPaletteComponent } from './module/custom-component/color-picker/color-palette/color-palette.component';
import { ColorPickerNoHideComponent } from './module/custom-component/color-picker/color-picker-no-hide/color-picker-no-hide.component';
import { ColorPickerComponent } from './module/custom-component/color-picker/color-picker.component';
import { OpacitySliderComponent } from './module/custom-component/color-picker/opacity-slider/opacity-slider.component';
import { ControlMessagesComponent } from './module/custom-component/control-messages/control-messages.component';
import { CustomDatePickerComponent } from './module/custom-component/custom-date-picker/custom-date-picker.component';
import { CustomInputTimeComponent } from './module/custom-component/custom-input-time/custom-input-time.component';
import { CustomMultipleDatePickerComponentI18n } from './module/custom-component/custom-multiple-date-picker/custom-multiple-date-picker-i18n';
import { CustomMultipleDatePickerComponent } from './module/custom-component/custom-multiple-date-picker/custom-multiple-date-picker.component';
import { CustomMultipleSelectionComponent } from './module/custom-component/custom-multiple-selection/custom-multiple-selection.component';
import { CustomSelectBoxComponent } from './module/custom-component/custom-select-box/custom-select-box.component';
import { CustomPhoneInputComponent } from './module/custom-phone-input/custom-phone-input.component';
import { DashboardComponent } from './module/dashboard/dashboard.component';
import { DeliveryManagerComponent } from './module/delivery-manager/delivery-manager.component';
import { DestinationSignEditorComponent } from './module/destination-sign-editor/destination-sign-editor.component';
import { DeviceManagerComponent } from './module/device-manager/device-manager.component';
import { DigitalSignageContentEditorComponent } from './module/digital-signage-content-editor/digital-signage-content-editor.component';
import { EdsEditorComponent } from './module/eds-editor/eds-editor.component';
import { ExternalContentManagerComponent } from './module/external-content-manager/external-content-manager.component';
import { IndexWordEditorComponent } from './module/index-word-editor/index-word-editor.component';
import { LcdLayoutEditorComponent } from './module/lcd-layout-editor/lcd-layout-editor.component';
import { LedLayoutEditorComponent } from './module/led-layout-editor/led-layout-editor.component';
import { MasterListEditorComponent } from './module/master-list-editor/master-list-editor.component';
import { MediaManagerComponent } from './module/media-manager/media-manager.component';
import { ProjectManagerComponent } from './module/project-manager/project-manager.component';
import { RouteListEditorComponent } from './module/route-list-editor/route-list-editor.component';
import { ScheduleMergeComponent } from './module/schedule-merge/schedule-merge.component';
import { LazyScrollDirective } from './module/schedule-operation-manager/lazy-scroll.directive';
import { ScheduleOperationManagerComponent } from './module/schedule-operation-manager/schedule-operation-manager.component';
import { ScheduleRegistrationComponent } from './module/schedule-registration/schedule-registration.component';
import { SignageDisplayEditorComponent } from './module/signage-display-editor/signage-display-editor.component';
import { SimpleSignageEditorComponent } from './module/simple-signage-editor/simple-signage-editor.component';
import { StationDisplayEditorComponent } from './module/station-display-editor/station-display-editor.component';
import { PreviewTabApplicationComponent } from './module/ticket-editor/ticket-editor-tab-application/preview-tab-application/preview-tab-application.component';
import { TicketEditorTabApplicationComponent } from './module/ticket-editor/ticket-editor-tab-application/ticket-editor-tab-application.component';
import { TicketEditorTabCombinationComponent } from './module/ticket-editor/ticket-editor-tab-combination/ticket-editor-tab-combination.component';
import { TicketEditorTabCrewAccountComponent } from './module/ticket-editor/ticket-editor-tab-crew-account/ticket-editor-tab-crew-account.component';
import { TicketEditorTabReservationComponent } from './module/ticket-editor/ticket-editor-tab-reservation/ticket-editor-tab-reservation.component';
import { TicketEditorTabSpotComponent } from './module/ticket-editor/ticket-editor-tab-spot/ticket-editor-tab-spot.component';
import { PreviewTabTicketComponent } from './module/ticket-editor/ticket-editor-tab-ticket/preview-tab-ticket/preview-tab-ticket.component';
import { TicketEditorTabTicketComponent } from './module/ticket-editor/ticket-editor-tab-ticket/ticket-editor-tab-ticket.component';
import { TicketEditorComponent } from './module/ticket-editor/ticket-editor.component';
import { TabReportComponent } from './module/ticket-manager/tab-report/tab-report.component';
import { TabReservationManagerComponent } from './module/ticket-manager/tab-reservation-manager/tab-reservation-manager.component';
import { TabReservationComponent } from './module/ticket-manager/tab-reservation/tab-reservation.component';
import { TabSalesRecordComponent } from './module/ticket-manager/tab-sales-record/tab-sales-record.component';
import { TicketManagerComponent } from './module/ticket-manager/ticket-manager.component';
import { TimetableEditorComponent } from './module/timetable-editor/timetable-editor.component';
import { TimetableOperationManagerComponent } from './module/timetable-operation-manager/timetable-operation-manager.component';
import { UserManagerComponent } from './module/user-manager/user-manager.component';
import { LocalTimePipe } from './pipes/local-time';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { TimetableOperationDatePipe } from './pipes/timetable-operation-date.pipe';
import { ImageCropperModule } from './public_api';
import { LoginModalComponent } from './shared/login/login.component';
import { TableSortFilterComponent } from './shared/table-sort-filter/table-sort-filter.component';
import { appReducer } from './store/app.reducer';
import './vendor';
import { TabSaleResultComponent } from './module/ticket-manager/tab-sale-result/tab-sale-result.component';
import { DialogReissueReceiptComponent } from './dialog/dialog-reissue-receipt/dialog-reissue-receipt.component';
import { QuillComponent } from './module/schedule-operation-manager/quill/quill.component';
import { DialogConfirmDeleteReservationComponent } from './dialog/dialog-confirm-delete-reservation/dialog-confirm-delete-reservation.component';
import { DialogResultDeleteReservationComponent } from './dialog/dialog-result-delete-reservation/dialog-result-delete-reservation.component';
import { DialogChangeTimelineSettingComponent } from './dialog/dialog-change-timeline-setting/dialog-change-timeline-setting.component';
import { DialogBackgroundColorSettingComponent } from './dialog/dialog-background-color-setting/dialog-background-color-setting.component';
import { CustomMultipleSelectionComponent1 } from './module/custom-component/custom-multiple-selection1/custom-multiple-selection1.component';
@NgModule({
  imports: [
    BrowserModule,
    GatewaySharedModule,
    GatewayCoreModule,
    GatewayHomeModule,
    // jhipster-needle-angular-add-module JHipster will add new module here
    GatewayAppRoutingModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    AutofocusFixModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 800,
      positionClass: 'toast-bottom-right'
    }),
    StoreModule.forRoot(appReducer, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false
      }
    }),
    DragDropModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatDialogModule,
    FormsModule,
    NgxPaginationModule,
    ImageCropperModule,
    DpDatePickerModule,
    MatDatepickerModule,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    NgbModule
  ],
  declarations: [
    AppComponent,
    MainComponent,
    ErrorComponent,
    ProjectManagerComponent,
    MediaManagerComponent,
    DashboardComponent,
    DeliveryManagerComponent,
    BusInformationDisplayEditorComponent,
    IndexWordEditorComponent,
    MasterListEditorComponent,
    RouteListEditorComponent,
    DeviceManagerComponent,
    LcdLayoutEditorComponent,
    UserManagerComponent,
    DigitalSignageContentEditorComponent,
    DestinationSignEditorComponent,
    DialogUserManagerComponent,
    DialogProjectManagerComponent,
    DialogBusStopDetailComponent,
    DialogConfirmComponent,
    AnchorPickerComponent,
    DialogTemplateGroupDetailComponent,
    DialogMessageComponent,
    DialogTextHighlightingSettingComponent,
    InfoTextHighlightingSettingComponent,
    ColorPickerComponent,
    ColorPickerNoHideComponent,
    ColorPaletteComponent,
    ColorPalette330Component,
    OpacitySliderComponent,
    AutoFocusInvalidInputDirective,
    ControlMessagesComponent,
    DialogChangeTemplateComponent,
    DialogPublishDataBusInformationComponent,
    DialogChangeLabelComponent,
    DialogDeviceManagerComponent,
    DialogRouteLabelManagerComponent,
    DialogSelectGroupComponent,
    DialogEditGroupComponent,
    BusInformationDisplayEditorComponent,
    DialogPlaylistRecurrenceComponent,
    DialogPlaylistComponent,
    DialogUpperLimit,
    DialogChannelComponent,
    DialogCustomSortComponent,
    DialogTemplateDetailComponent,
    DialogTimetableUpdateTimingComponent,
    DialogSimpleSyncSettingComponent,
    DialogPublishDataSignageChannelComponent,
    DialogAddSequenceComponent,
    DialogSettingComponent,
    DialogListCustomTagComponent,
    DialogDisplayDeviceComponent,
    DialogDeliveryUploadComponent,
    DialogExtractSettingComponent,
    DialogSettingProgramComponent,
    LayoutComponent,
    Layout1Component,
    Layout2Component,
    Layout3Component,
    Layout4Component,
    Layout5Component,
    Layout6Component,
    MenuUserComponent,
    MenuProjectComponent,
    MenuDeviceComponent,
    MenuMediaComponent,
    MenuIndexWordComponent,
    MenuMasterListComponent,
    MenuRouteListComponent,
    MenuDestinationSignComponent,
    MenuLcdComponent,
    MenuBusInformationComponent,
    MenuDigitalSignageComponent,
    MenuDeliveryComponent,
    MenuDashboardComponent,
    CustomDatePickerComponent,
    StationDisplayEditorComponent,
    MenuStationDisplayEditorComponent,
    MediaDragDirective,
    DialogPublishDataStationComponent,
    ExecutingComponent,
    DialogChangeTemplateForDisplayComponent,
    CustomSelectBoxComponent,
    CustomMultipleSelectionComponent,
    DisableAfterClickDirective,
    DialogChangeTemplateForDisplayComponent,
    SignageDisplayEditorComponent,
    MenuSignageDisplayEditorComponent,
    DialogPublishDataSignageDisplayComponent,
    CustomFocusoutDirective,
    CustomInputTimeComponent,
    BlockCopyPasteDirective,
    DialogEmergencyModeComponent,
    CustomFocusoutDirective,
    DialogDeliveryJobComponent,
    DialogDeliveryManagerSettingComponent,
    DialogDragDropDirective,
    DialogSettingSignageDisplayComponent,
    LocalTimePipe,
    DialogSettingExternalContentComponent,
    ExternalContentManagerComponent,
    MenuExternalContentManagerComponent,
    DialogChangeTemplateForExternalContentComponent,
    DialogChangePasswordComponent,
    CheckPrivilegesDirective,
    DialogSettingDeviceMonitorAutoRefreshComponent,
    TimetableOperationManagerComponent,
    MenuTimetableOperationManagerComponent,
    TimetableEditorComponent,
    MenuTimetableEditorComponent,
    DialogEditHeaderComponent,
    DialogDeliveryTimetableComponent,
    TimetableOperationDatePipe,
    SimpleSignageEditorComponent,
    MenuSimpleSignageEditorComponent,
    DialogDeliverySimpleComponent,
    BlockDropInInputTextDirective,
    DialogReferenceSettingComponent,
    DialogExportCalendarComponent,
    DialogOperationInformationSettingComponent,
    DialogOperationInformationSettingScheduleComponent,
    DialogSimpleSignageMessageComponent,
    DialogChangeDateLineComponent,
    LocalizedDatePipe,
    LedLayoutEditorComponent,
    MenuLedLayoutEditorComponent,
    DialogTemplateGroupLedComponent,
    DialogTemplateLedComponent,
    DialogChangeTemplateForDestinationComponent,
    EdsEditorComponent,
    ColorPickerLedComponent,
    DialogDeliveryDestinationComponent,
    SafeUrlPipe,
    DialogTimetableSwitchTimingAreaComponent,
    DialogDownloadDataComponent,
    DialogHelpComponent,
    ScheduleMergeComponent,
    ScheduleOperationManagerComponent,
    MenuScheduleMergeComponent,
    MenuScheduleOperationManagerComponent,
    DialogTemplateSettingScheduleComponent,
    ScheduleRegistrationComponent,
    MenuScheduleRegistrationComponent,
    DialogTemplateSettingScheduleRegistrationComponent,
    DialogShowHiddenItemSettingSettingComponent,
    LazyScrollDirective,
    TableSortFilterComponent,
    DialogMessageOperationComponent,
    DialogEmergencyOperationComponent,
    DialogCreateDailyScheduleComponent,
    DialogCallApiCycleComponent,
    DialogApiDetailsComponent,
    DialogChooseCityComponent,
    DialogSettingAutoUpdateComponent,
    DialogPlaybackTimeComponent,
    AnnouncementManagerComponent,
    MenuAnnouncementManagerComponent,
    DialogPlayTimingSettingComponent,
    DialogSpecificSequenceSettingComponent,
    DialogDeliveryAnnouncementComponent,
    DialogAutoUpdatePlaylistComponent,
    MenuTicketEditorComponent,
    TicketEditorComponent,
    TicketEditorTabTicketComponent,
    TicketEditorTabCombinationComponent,
    TicketEditorTabReservationComponent,
    TicketEditorTabSpotComponent,
    TicketEditorTabApplicationComponent,
    TicketEditorTabCrewAccountComponent,
    TicketManagerComponent,
    MenuTicketManagerComponent,
    TabReservationComponent,
    TabReportComponent,
    TabSalesRecordComponent,
    DialogWebRefundTicketComponent,
    DialogDeliveryApplicationTicketEditorComponent,
    PreviewTabApplicationComponent,
    DialogCreatePlaylistTabTranslationSpeechSynthesisComponent,
    DialogDuplicateApplicationDesignComponent,
    PreviewTabTicketComponent,
    DialogGlossaryComponent,
    DialogTrimingComponent,
    DialogTranslateTicketComponent,
    DialogRouteSettingComponent,
    DialogPageSwitchingTimingComponent,
    DialogDuplicateSettingComponent,
    DialogPageSwitchingTimingComponent,
    DialogAreaSettingComponent,
    DialogAreaItemSettingComponent,
    DialogConfirmWebRefundTicketComponent,
    MenuSpotComponent,
    DialogConfirmSaleTicketComponent,
    CustomMultipleDatePickerComponent,
    DialogConfirmDeleteAppComponent,
    DialogExportReservationComponent,
    PriceTableComponent,
    TabReservationManagerComponent,
    DialogAddReservationComponent,
    DialogConfirmReservationComponent,
    DialogEditReservationComponent,
    CustomPhoneInputComponent,
    DialogConfirmWithMultipleMessagesComponent,
    TabSaleResultComponent,
    DialogReissueReceiptComponent,
    QuillComponent,
    DialogConfirmDeleteReservationComponent,
    DialogResultDeleteReservationComponent,
    DialogChangeTimelineSettingComponent,
    DialogBackgroundColorSettingComponent,
    CustomMultipleSelectionComponent1
  ],
  entryComponents: [
    LoginModalComponent,
    ProjectManagerComponent,
    DeviceManagerComponent,
    MediaManagerComponent,
    MasterListEditorComponent,
    RouteListEditorComponent,
    IndexWordEditorComponent,
    LcdLayoutEditorComponent,
    LedLayoutEditorComponent,
    DestinationSignEditorComponent,
    BusInformationDisplayEditorComponent,
    DigitalSignageContentEditorComponent,
    DeliveryManagerComponent,
    DashboardComponent,
    UserManagerComponent,
    DialogBusStopDetailComponent,
    LayoutComponent,
    Layout1Component,
    Layout2Component,
    Layout3Component,
    Layout4Component,
    Layout5Component,
    Layout6Component
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: ApplicationInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    },
    CustomMultipleDatePickerComponentI18n
  ],
  bootstrap: [AppComponent]
})
export class GatewayAppModule {}
